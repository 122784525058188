<template>
    <footer class="bg-secondary text-white">
        <div class="max-w-7xl mx-auto px-6 py-12 md:py-16">
            <!-- Main Footer Content -->
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12">
                <!-- Logo Section -->
                <div class="flex items-center justify-center lg:justify-start">
                    <img src="../../src/assets/logo.png" alt="RHDyslexia Logo" class="w-48 md:w-56">
                </div>

                <!-- Contact Section -->
                <div class="space-y-4">
                    <h4 class="text-lg font-semibold relative inline-block">
                        Contact me
                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
                    </h4>
                    <div class="flex flex-col space-y-3">
                        <a href="tel:07349159784" class="hover:text-primary transition-colors duration-300">
                            07349 159784
                        </a>
                        <a href="mailto:info@rhdyslexiaservices.co.uk"
                            class="hover:text-primary transition-colors duration-300">
                            info@rhdyslexiaservices.co.uk
                        </a>
                    </div>
                </div>

                <!-- Legal Section -->
                <div class="space-y-4">
                    <h4 class="text-lg font-semibold relative inline-block">
                        Legal
                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
                    </h4>
                    <div class="flex flex-col space-y-3">
                        <a href="/Privacy-Policy.pdf" target="_blank"
                            class="hover:text-primary transition-colors duration-300">
                            Privacy Policy
                        </a>
                    </div>
                </div>

                <!-- Business Hours Section -->
                <div class="space-y-4">
                    <h4 class="text-lg font-semibold relative inline-block">
                        Business Hours
                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
                    </h4>
                    <div class="flex flex-col space-y-3">
                        <p>Monday - Friday: 9:00 AM - 5:00 PM</p>
                    </div>
                </div>

                <!-- Attribution Section -->
                <div class="space-y-4">
                    <h4 class="text-lg font-semibold relative inline-block">
                        Credits
                        <span class="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
                    </h4>
                    <div class="space-y-2">
                        <p>
                            Images supplied by
                            <a href="https://pixabay.com/" target="_blank"
                                class="text-primary hover:underline">Pixabay</a>,
                            <a href="https://unsplash.com/" target="_blank"
                                class="text-primary hover:underline">Unsplash</a> and
                            <a href="https://www.pexels.com/" target="_blank"
                                class="text-primary hover:underline">Pexels</a>
                        </p>
                    </div>
                </div>
            </div>

            <!-- Copyright Bar -->
            <div class="mt-12 pt-8 border-t border-white/10 text-center text-sm text-gray-400">
                © 2024 RHDyslexia Services. All rights reserved.
            </div>
        </div>
    </footer>
</template>